export const mottagningenEnglishLocale = {
  headingOne: 'JC Psykologmottagning AB',
/*   paragraphOne:
    'At the clinic, I conduct both Cognitive Behavioral Therapy (CBT) and Psychodynamic therapy (PDT) and treatments under a healthcare tariff and high-cost protection after receiving a referral from a family doctor for individuals aged 16 years and older.', */
    // endast kbt
    paragraphOne:
    'At the clinic, I conduct Cognitive Behavioral Therapy (CBT) and treatments under a healthcare tariff and high-cost protection after receiving a referral from a family doctor for individuals aged 16 years and older.',
  headingTwo: 'Appointment',
  paragraphTwo:
    'I have availability through appointments. Call or send an e-mail, and we will arrange a time that suits you.',
  paragraphThree: `A warm welcome.`,
  // KBT
  headingThree: `Cognitive behavioral therapy`,
  paragraphFour: `Cognitive behavioral therapy (CBT) focuses on changing thoughts, behaviors and habits to help people live a better life.`,
  paragraphFive: `This type of therapy has achieved particularly good results on common anxiety conditions, e.g. phobias, panic disorder and obsessive-compulsive disorder, and on mood disorders, e.g. Depression.`,
  paragraphSix: `In comparison with other forms of therapy, better results with CBT have usually been noted.`,
  // PDT
  pdtHeading: `Psychodynamic therapy`,
  pdtParagraphOne: `Psychodynamic therapy (PDT) is another well-established treatment method that focuses on exploring unconscious patterns and experiences that affect an individual's current behavior, feelings and relationships to promote personal development and self-awareness.`,
  pdtParagraphTwo: `This type of therapy has shown good results in treating emotional problems, relationship problems and anxiety problems, as well as improving self-esteem and self-image.`,
  pdtParagraphThree: `In comparison with CBT, PDT is more focused on exploring and understanding deeper underlying causes of problems rather than focusing solely on changing symptoms and behaviors.`
}
