import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { sweMenuLinks, engMenuLinks } from '../../../constants'
import { ActiveLink, MenuLink } from '../../../utilities'
import { NavbarStyles, NavbarMobile, NavbarDesktop } from '../../UIComponents'
import { Hamburger } from './Hamburger'
import { ModalMenu } from './ModalMenu'

/**
 * The navbar component.
 *
 * @param {*} props - The props.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const Navbar = props => {
  const { navbarMobileContainerRef, navbarDesktopRef, desktopLogoRef } = props
  const [navbarOpen, setNavbarOpen] = useState(false)
  const desktopMediaQuery = useMediaQuery({
    query: '(min-width: 649px)'
  })

  const mobileMediaQuery = useMediaQuery({
    query: '(max-width: 648px)'
  })

  const router = useRouter()
  const { locale } = router

  /**
   * Function to toggle nav.
   */
  function toggleNav() {
    setNavbarOpen(previousState => !previousState)
  }

  /**
   * Function to close the nav.
   *
   */
  function closeModal() {
    setNavbarOpen(false)
  }

  /**
   * Function to close the nav.
   *
   * @param event
   */
  function closeModalEvent(event) {
    if (
      event.target.classList.contains('navbar-hamburger-button') ||
      event.target.classList.contains('overlay-container')
    ) {
      setNavbarOpen(false)
    }
  }

  /**
   * Function to render menu links based on locale.
   *
   * @returns {HTMLElement} - The HTML Markup.
   */
  function renderMenuLinks() {
    if (locale === 'en') {
      return engMenuLinks.map(link => {
        return (
          <li key={link.id}>
            <MenuLink href={link.path} navbarOpen={navbarOpen} closeModal={closeModal}>
              {link.text}
            </MenuLink>
          </li>
        )
      })
    } else {
      return sweMenuLinks.map(link => {
        return (
          <li key={link.id}>
            <MenuLink href={link.path} navbarOpen={navbarOpen} closeModal={closeModal}>
              {link.text}
            </MenuLink>
          </li>
        )
      })
    }
  }

  /**
   * Function to render active links based on locale.
   *
   * @returns {HTMLElement} - The HTML Markup.
   */
  function renderActiveLinks() {
    if (locale === 'en') {
      return engMenuLinks.map(link => {
        return (
          <li key={link.id}>
            <ActiveLink href={link.path} navbarOpen={navbarOpen} closeModal={closeModal}>
              {link.text}
            </ActiveLink>
          </li>
        )
      })
    } else {
      return sweMenuLinks.map(link => {
        return (
          <li key={link.id}>
            <ActiveLink href={link.path} navbarOpen={navbarOpen} closeModal={closeModal}>
              {link.text}
            </ActiveLink>
          </li>
        )
      })
    }
  }

  useEffect(() => {
    if (desktopMediaQuery && navbarOpen) {
      setNavbarOpen(false)
    } else if (mobileMediaQuery) {
      navbarMobileContainerRef.current.style.display = 'flex'
      navbarDesktopRef.current.style.display = 'none'
      desktopLogoRef.current.style.display = 'none'
    } else if (desktopMediaQuery) {
      navbarMobileContainerRef.current.style.display = 'none'
      navbarDesktopRef.current.style.display = 'flex'
      desktopLogoRef.current.style.display = 'flex'
    }
  }, [desktopMediaQuery, mobileMediaQuery, navbarOpen])

  return (
    <NavbarStyles>
      <NavbarMobile id="navbar-mobile-container" ref={navbarMobileContainerRef}>
        {/* Mobile */}
        <Hamburger
          className="navbar-hamburger-button"
          toggleNav={toggleNav}
          navbarOpen={navbarOpen}
          aria-label="Navigation"
          aria-expanded={navbarOpen}
          role="checkbox"
          tabIndex={0}
        />

        <ModalMenu
          renderMenuLinks={renderMenuLinks}
          navbarOpen={navbarOpen}
          onRequestClose={closeModal}
          closeModalEvent={closeModalEvent}
        />

        <div id="mobile-nav-items">
          <ul id="logo-section-mobile">
            <li>
              <ActiveLink href="/">
                <img className="logo-mobile-img" src="/img/logo-full.svg" alt="JC Logo" />
              </ActiveLink>
            </li>
          </ul>
        </div>
      </NavbarMobile>
      {/* Desktop */}
      <NavbarDesktop ref={navbarDesktopRef}>
        <div className="desktop-nav-items">
          <ul className="desktop-nav-ul">{renderActiveLinks()}</ul>
        </div>
      </NavbarDesktop>
    </NavbarStyles>
  )
}
