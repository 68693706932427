import { Analytics } from '@vercel/analytics/react'
import { DefaultSeo } from 'next-seo'
import { ThemeProvider } from 'theme-ui'
import SEO from '../../next-seo.config'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { ErrorFallback } from '../components/ErrorFallback'
import { Layout } from '../components/Layout/Layout'
import theme from '../theme'

/**
 * The App component.
 *
 * @param {*} props - The props.
 * @returns {HTMLElement} - The HTML Markup.
 */
function MyApp(props) {
  const { Component, pageProps } = props
  return (
    <ThemeProvider theme={theme}>
      <DefaultSeo {...SEO} />
      <Layout>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Component {...pageProps} />
          <Analytics />
        </ErrorBoundary>
      </Layout>
    </ThemeProvider>
  )
}

export default MyApp
