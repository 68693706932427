export const homeSwedishLocale = {
  welcome: 'Välkommen till JC Psykologmottagning AB i centrala Helsingborg',
/*   welcomeInfo:
    'Verksamheten erbjuder behandlingar inom ramen för vårdval psykoterapi, på uppdrag av Region Skåne, med fokus på Kognitiv Beteendeterapi (KBT) och Psykodynamisk terapi (PDT).', */
    // endast kbt
    welcomeInfo: 'Verksamheten erbjuder behandlingar inom ramen för vårdval psykoterapi, på uppdrag av Region Skåne, med fokus på Kognitiv Beteendeterapi (KBT).',
  welcomeOne: 'Mitt medlemskap i',
  welcomeTwo: 'Sveriges Psykologförbund',
  welcomeThree: 'samt',
  welcomeFour: 'Psykologföretagarna',
  welcomeFive: 'garanterar din säkerhet som patient.',
  costTitle: 'Kostnad',
  costParagraphOne:
    '200 kr per besök eller kostnadsfritt med frikort, efter remiss från din husläkare. Utan remiss 1 400 kr per besök.',
  feelingOne:
    'Känner du dig missnöjd med ditt liv, även om allt verkar bra? Upplever du besvär eller symtom av olika slag?',
  feelingTwo: 'Sådana symtom kan vara:',
  feelingThree: 'Depression, nedstämdhet',
  feelingFour: 'Oro, ångest, panikattacker',
  feelingFive: 'Stress och krisreaktioner',
/*   feelingSix:
    'Här erbjuds både KBT- och PDT-behandling med sjukvårdstaxa samt högkostnadsskydd. Detta gäller dig från 16 år.', */
    feelingSix: // endast kbt
    'Här erbjuds KBT-behandling med sjukvårdstaxa samt högkostnadsskydd. Detta gäller dig från 16 år.',
    
  feelingSeven:
    'Kognitiv beteendeterapi (KBT) är en evidensbaserad behandlingsmetod som har visat sig vara effektiv vid behandling av bland annat ångest, depression och stressrelaterade besvär.',
  feelingEight:
    'Psykodynamisk terapi (PDT) är en annan väl beprövad behandlingsmetod som fokuserar på att utforska och förstå underliggande känslor och mönster i relation till nuvarande problem och besvär.'
}
