import Modal from 'react-modal'

/**
 * Renders the modal component.
 *
 * @param {*} props - The props.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const ModalComponent = props => {
  const { children, className, navbarOpen, onRequestClose } = props

  Modal.setAppElement('#__next')

  return (
    <Modal
      {...props}
      className={`${className}`}
      overlayClassName={'ReactModal__container'}
      isOpen={navbarOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      {children}
    </Modal>
  )
}
