import styled from '@emotion/styled'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { footerEnglishLocale, footerSwedishLocale } from '../../../locales'

import { EnvelopeIcon, PhoneIcon } from '../../UIComponents'

/**
 * The footer.
 *
 * @returns {HTMLElement} - The HTML Markup.
 *
 */
export const Footer = () => {
  const router = useRouter()
  const { locale } = router
  const t = locale === 'en' ? footerEnglishLocale : footerSwedishLocale

  return (
    <FooterStyles>
      <div className="footer-grid-item">
        <h5>{t.headingOne}</h5>
        <p>
          JC Psykologmottagning AB
          <br />
          Kullagatan 8
          <br />
          252 20 Helsingborg
        </p>
      </div>
      <div className="footer-grid-item">
        <h5>{t.headingTwo}</h5>
        <div className="list">
          <PhoneIcon className="hero-icon" aria-hidden="true" />
          <a href="tel:0706-888950">0706 - 88 89 50</a>
        </div>

        <div className="list">
          <EnvelopeIcon className="hero-icon" aria-hidden="true" />
          <a href="mailto:jonas@jcpsykologmottagning.se">jonas@jcpsykologmottagning.se</a>
        </div>
      </div>

      <div className="footer-grid-item">
        <h5>{t.headingThree}</h5>
        <div className="center-images">
          <div className="image-item">
            <a
              href="http://www.psykologforbundet.se/"
              target="_blank"
              rel="noreferrer"
              aria-label="Länk till Psykologförbundet (öppnas i nytt fönster)"
              title="Länk till Psykologförbundet (öppnas i nytt fönster)"
            >
              <Image
                src="/img/logo/psykologforbund.svg"
                layout="fill"
                objectFit="contain"
                quality="100"
                alt="Psykologförbundet"
                loading="eager"
              />
            </a>
          </div>
          <div id="psykolog-item" className="image-item">
            <a
              href="http://www.psykologforbundet.se/psykologforetagarna/"
              target="_blank"
              rel="noreferrer"
              aria-label="Länk till Psykologföretagarna (öppnas i nytt fönster)"
              title="Länk till Psykologföretagarna (öppnas i nytt fönster)"
            >
              <Image
                src="/img/logo/psykologforetagarna.png"
                layout="fill"
                objectFit="contain"
                quality="100"
                alt="Psykologföretagarna"
                loading="eager"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-grid-item">
        <h5>{t.headingFour}</h5>
        <div className="center-images">
          <div className="image-item">
            <a
              href="http://polisen.se/"
              target="_blank"
              rel="noreferrer"
              aria-label="Länk till Polisen (öppnas i nytt fönster)"
              title="Länk till Polisen (öppnas i nytt fönster)"

            >
              <Image
                src="/img/logo/polisen.svg"
                layout="fill"
                objectFit="contain"
                quality="100"
                alt="Polisen"
                loading="eager"
              />
            </a>
          </div>
          <div className="image-item">
            <a
              href="http://www.falcksverige.se/"
              target="_blank"
              rel="noreferrer"
              aria-label="Länk till Falck (öppnas i nytt fönster)"
              title="Länk till Falck (öppnas i nytt fönster)"
            >
              <Image
                src="/img/logo/falck.png"
                layout="fill"
                objectFit="contain"
                quality="100"
                alt="Falck"
                loading="eager"
              />
            </a>
          </div>
          <div className="image-item">
            <a
              href="https://www.sos.dk/sv/privat/"
              target="_blank"
              rel="noreferrer"
              aria-label="Länk till SOS (öppnas i nytt fönster)"
              title="Länk till SOS (öppnas i nytt fönster)"
            >
              <Image
                src="/img/logo/sos.png"
                layout="fill"
                objectFit="contain"
                quality="100"
                alt="SOS"
                loading="eager"
              />
            </a>
          </div>
        </div>
      </div>
    </FooterStyles>
  )
}

const FooterStyles = styled.footer`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem 2rem;
  background-color: #efefef;
  border-top: 1px solid #e7e7e7;
  gap: 1rem;
  text-align: center;

  @media only screen and (max-width: 1170px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 648px) {
    grid-template-columns: 1fr;
  }

  .footer-grid-item {
    justify-self: center;
    height: 100%;
  }

  .list {
    display: flex;
    align-items: center;
  }

  .list a {
    text-decoration: none;
    color: inherit;
  }

  .list a:hover {
    text-decoration: underline;
  }

  h5 {
    /* font-size: 0.8rem;
    font-weight: 400; */
    margin-bottom: 1rem;
    text-align: center;
    /* font-style: italic; */
  }

  .footer-grid-item .center-images {
    display: flex;
    align-items: center;
  }

  .footer-grid-item .image-item {
    position: relative;
    width: 70px;
    height: 70px;
  }

  .footer-grid-item #psykolog-item {
    width: 120px;
    height: 70px;
  }
`
