export const engMenuLinks = [
  {
    id: 1,
    path: '/',
    text: 'Start'
  },
  {
    id: 2,
    path: '/bakgrund',
    text: 'Background'
  },
  {
    id: 3,
    path: '/mottagningen',
    text: 'The Clinic'
  },
  {
    id: 4,
    path: '/hitta-hit',
    text: 'Location'
  }
]
