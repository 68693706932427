import { useEffect } from 'react'

/**
 * Function to disable scroll.
 *
 * @param {*} navbarOpen - the parameter.
 */
function useDisableBodyScroll(navbarOpen) {
  useEffect(() => {
    if (navbarOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [navbarOpen])
}

export { useDisableBodyScroll }
