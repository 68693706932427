import { Themed } from 'theme-ui'

export const Error = props => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Themed.h4>{props.message}</Themed.h4>
    </div>
  )
}

Error.defaultProps = {
  message: 'Ett fel har inträffat...'
}
