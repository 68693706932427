export const homeEnglishLocale = {
  welcome: 'Welcome to JC Psykologmottagning AB in the centrum of Helsingborg',
/*   welcomeInfo:
    'The business performs treatments within the realm of care choice psychotherapy, on behalf of Region Skåne, focusing on Cognitive Behavioral Therapy (CBT) and Psychodynamic Therapy (PDT).', */
    // endast kbt
    welcomeInfo: 'The business performs treatments within the realm of care choice psychotherapy, on behalf of Region Skåne, focusing on Cognitive Behavioral Therapy (CBT).',
  welcomeOne: 'My membership in',
  welcomeTwo: 'Sveriges Psykologförbund',
  welcomeThree: 'together with',
  welcomeFour: 'Psykologföretagarna',
  welcomeFive: 'guarantees your safety as a patient.',
  costTitle: 'Cost',
  costParagraphOne:
    'SEK 200 per visit or free of charge with an exemption card (frikort), after a referral from your family doctor. SEK 1,400 per visit without referral.',
  feelingOne:
    'Are you not happy with your life, even though everything seems fine? Do you feel bothered by symptoms of various kinds?',
  feelingTwo: 'Such symptoms may be:',
  feelingThree: 'Depression, feeling low',
  feelingFour: 'Worriness, anxiety, panic attacks',
  feelingFive: 'Stress and crisis reactions',
/*   feelingSix:
    'Both CBT and PDT treatments are offered with a healthcare tariff and high-cost protection. This applies to you from 16 years old and above.', */
    // endast kbt
    feelingSix: 
    'CBT treatments are offered with a healthcare tariff and high-cost protection. This applies to you from 16 years old and above.',
  feelingSeven:
    'Cognitive Behavioral Therapy (CBT) is a widely recognized, evidence-based treatment method that has been found to be effective in addressing concerns such as anxiety, depression, and stress-related symptoms.',
  feelingEight:
    "Psychodynamic Therapy (PDT) is another well-established treatment method that emphasizes the exploration and comprehension of the underlying emotions and patterns connected to one's present challenges and symptoms."
}
