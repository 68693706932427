import { useRouter } from 'next/router'

/**
 * @param props
 */
export function MenuLink(props) {
  const { children, href, navbarOpen, closeModal } = props
  const router = useRouter()
  const style = {
    textDecoration: router.pathname === href && 'underline'
  }

  /**
   * @param event
   */
  function handleClick(event) {
    event.preventDefault()
    if (navbarOpen) {
      closeModal()
    }
    router.push(href)
  }

  return (
    <a href={href} onClick={handleClick} style={router.pathname === href ? style : null}>
      {children}
    </a>
  )
}
