import styled from '@emotion/styled'

import Link from 'next/link'

export const PrimaryLink = props => {
  const { className = '', children } = props
  return (
    <Link {...props} className={`${className}`}>
      <StyledLink>{children}</StyledLink>
    </Link>
  )
}

const StyledLink = styled.a`
  /* line-height: ${props => (props.small ? 'unset' : '3rem')}; */
  text-decoration: none;
  color: ${({ theme }) => theme.colors.accent};
  -webkit-appearance: none;
  transition: background-color 0.2s ease, color 0.2s ease;
  outline: none;
  cursor: pointer;
  &:active {
    transform: translateY(3px);
  }
  &:hover {
    text-decoration: underline;
  }
  &:focus-visible {
    outline: 3px solid #6c60a6;
  }
`
