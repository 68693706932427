export const mottagningenSwedishLocale = {
  headingOne: 'JC Psykologmottagning AB',
/*   paragraphOne:
    'På mottagningen utför jag både kognitiv beteendeterapi-behandling (KBT) och psykodynamisk terapi-behandling (PDT) med sjukvårdstaxa och högkostnadsskydd efter remiss från husläkare för personer som är 16 år och äldre.', */
    // endast kbt
    paragraphOne:
    'På mottagningen utför jag kognitiv beteendeterapi-behandling (KBT) med sjukvårdstaxa och högkostnadsskydd efter remiss från husläkare för personer som är 16 år och äldre.',
  headingTwo: 'Tidsbeställning',
  paragraphTwo:
    'Jag har mottagning efter tidsbeställning. Ring eller skicka e-post, så avtalar vi en tid som passar dig.',
  paragraphThree: `Varmt välkommen hit.`,
  // KBT
  headingThree: `Kognitiv Beteendeterapi`,
  paragraphFour: `Kognitiv Beteendeterapi (KBT) fokuserar på att förändra tankar, beteenden och vanor för att hjälpa människor att leva ett bättre liv.`,
  paragraphFive: `Terapiformen har nått särskilt goda resultat på vanliga ångesttillstånd, t.ex. fobier, paniksyndrom och tvångssyndrom, och på förstämningssyndrom, t.ex. depression.`,
  paragraphSix: `I jämförelse med andra terapiformer har man vanligtvis noterat bättre resultat med KBT.`,
  // PDT
  pdtHeading: `Psykodynamisk terapi`,
  pdtParagraphOne: `Psykodynamisk terapi (PDT) är en annan väl beprövad behandlingsmetod som fokuserar på att utforska omedvetna mönster och upplevelser som påverkar en individs nuvarande beteende, känslor och relationer för att främja personlig utveckling och självförståelse.`,
  pdtParagraphTwo: `Denna terapiform har visat goda resultat för att behandla emotionella problem, relationsproblem och ångestproblematik, samt att förbättra självkänsla och självbild.`,
  pdtParagraphThree: `I jämförelse med KBT är PDT mer inriktad på att utforska och förstå djupare, underliggande orsaker till problem snarare än att enbart fokusera på att förändra symtom och beteenden.`
}
