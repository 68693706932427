import styled from '@emotion/styled'

import { ActiveLink } from '../../../utilities'

/**
 * The DesktopLogo component.
 *
 * @param {*} props - The props.
 * @returns {HTMLElement} - The HTML Markup.
 *
 */
const DesktopLogo = props => {
  const { desktopLogoRef } = props
  return (
    <DesktopLogoStyles ref={desktopLogoRef}>
      <li>
        <ActiveLink href="/">
          <div>
            <img className="logo-desktop-img" src="/img/logo-full.svg" alt="JC Logo" />
          </div>
        </ActiveLink>
      </li>
    </DesktopLogoStyles>
  )
}

const DesktopLogoStyles = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 120px;
  padding: 0 2rem;
  margin: auto;

  li {
    padding: 0;
  }

  img.logo-desktop-img {
    max-height: 76px;
  }

  @media only screen and (max-width: 648px) {
    display: none;
  }
`

export { DesktopLogo }
