import { Global, css } from '@emotion/react'

/**
 *
 */
export const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        :root {
          --navbar-height: 80px;
          --navbar-checkbox: 80px;
          --footer-height: 60px;
        }

        *,
        *::after,
        *::before {
          margin: 0;
          box-sizing: border-box;
        }

        html {
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          scroll-behavior: smooth;
        }

        body:not(ul) {
          padding: 0;
        }

        body {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          word-break: break-word;
          text-rendering: optimizeLegibility;
          background-color: #efefef;
          min-height: 100vh;
        }

        li a:hover {
          text-decoration: underline;
        }

        /* Animation */

        .fadein {
          animation: fadein 1s;
        }

        @keyframes fadein {
          from {
            opacity: 0.3;
          }
          to {
            opacity: 1;
          }
        }

        svg.hero-icon {
          width: 1.5rem;
          height: 1.5rem;
        }

        /* Utility */
        .center {
          text-align: center;
        }

        .ReactModal__container {
          background-color: unset;
        }
      `}
    />
  )
}
