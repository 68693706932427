import styled from '@emotion/styled'

export const Form = props => {
  const { className = '', children } = props
  return (
    <StyledForm {...props} className={`${className}`}>
      {children}
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 100%;
  max-width: inherit;
  textarea {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border: 3px solid transparent;
    border-radius: ${({ theme }) => theme.radii[1]};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    outline: 0;
    padding: 10px 45px 0 10px;
    resize: none;
    box-shadow: ${({ theme }) => theme.shadows[2]};
    -webkit-appearance: none;
    transition: var(--transitionPrimary);
  }

  input {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    border: 3px solid transparent;
    border-radius: ${({ theme }) => theme.radii[1]};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    outline: 0;
    line-height: 3rem;
    padding: 0 45px 0 10px;
    box-shadow: ${({ theme }) => theme.shadows[2]};
    -webkit-appearance: none;
    transition: var(--transitionPrimary);
  }

  input:valid {
    border-bottom: 3px solid #55c57a;
  }

  input:focus:invalid {
    border-bottom: 3px solid #ff7730;
  }

  input::placeholder,
  textarea::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`
