import styled from '@emotion/styled'

import { useDisableBodyScroll } from '../../../hooks/useDisableScroll'
import { ModalComponent } from '../../../utilities'

/**
 * Renders the ModalMenu component.
 *
 * @param {*} props - The props.
 * @returns {HTMLElement} - The HTML Markup.
 */
const ModalMenu = props => {
  const { renderMenuLinks, closeModalEvent } = props

  useDisableBodyScroll(props.navbarOpen)

  // console.log('ModalMenu Props', props)

  return (
    <ModalComponentStyles {...props}>
      <div
        className="overlay-container"
        onClick={event => closeModalEvent(event)}
        role="button"
        tabIndex={0}
      >
        <div className="modal-container">
          <ul>{renderMenuLinks()}</ul>
        </div>
      </div>
    </ModalComponentStyles>
  )
}

const ModalComponentStyles = styled(ModalComponent)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.navbarOpen ? 'visible' : 'hidden')};
  z-index: 2;

  .overlay-container {
    background-color: ${({ theme }) => theme.colors.navbarMenu};
    border-radius: 50%;
    width: 200vw;
    height: 200vh;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    transform: ${props => (props.navbarOpen ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease;
  }

  .modal-container {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: ${props => (props.navbarOpen ? '1' : '0')};
    transition: opacity 0.4s ease;
  }

  .modal-container ul {
    padding: 0;
    list-style: none;
    color: ${({ theme }) => theme.colors.background};
    font-size: 1.3rem;
    user-select: none;
  }
  .modal-container ul li {
    padding: 1rem 0;
  }

  .modal-container ul li a {
    color: inherit;
    text-decoration: none;
    transition: color 0.4s ease;
  }

  .modal-container ul li a:hover {
    color: #62ff62;
  }
`

export { ModalMenu }
