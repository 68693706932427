/* eslint-disable import/no-anonymous-default-export */
export default {
  title: "JC Psykologmottagning",
  description: "Mitt namn är Jonas Claesson. Jag är legitimerad psykolog sedan 1993 samt specialist inom klinisk psykologi sedan 2004",
  openGraph: {
    type: "website",
    locale: "sv_SE",
    url: "https://jcpsykologmottagning.se",
    site_name: "JC Psykologmottagning",
    title: "JC Psykologmottagning",
    description: "Mitt namn är Jonas Claesson. Jag är legitimerad psykolog sedan 1993 samt specialist inom klinisk psykologi sedan 2004",
    images: [
      {
        url: "/img/logo.svg",
        width: 1200,
        height: 1200,
        alt: "JC Psykologmottagning AB",
      },
    ],
  }
}
