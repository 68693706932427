/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'

/**
 * Renders the Main component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @param {*} props.children - the children.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const Main = props => {
  const { className = '', children } = props
  return (
    <main
      {...props}
      className={`${className}`}
      sx={{
        backgroundColor: 'background',
        minHeight: 'calc(100vh - var(--navbar-height) - var(--footer-height))',
        margin: [1]
      }}
    >
      {children}
    </main>
  )
}
