export const bakgrundSwedishLocale = {
  listItemOne: 'Legitimerad psykolog',
  listItemTwo: 'Specialist i klinisk psykologi',
  listItemThree: 'Ackrediterad vårdgivare för Region Skåne',
  backgroundTitle: 'Bakgrund',
  backgroundParagraphOne: `Mitt namn är Jonas Claesson. Jag är legitimerad psykolog sedan 1993 samt specialist inom klinisk psykologi sedan 2004.`,
  backgroundParagraphTwo: `Jag var under en period engagerad i ett forskningsprojekt kring säsongsbunden
  depression vid Lunds Universitetssjukhus. Vidare arbetar jag samt har arbetat med
  konsultation och utbildning till Malmö lärarhögskola, debriefingutbildning och
  krishantering till polismyndigheten i Skåne samt handledning till Kvinnojouren i
  Helsingborg.`,
  backgroundParagraphThree: `Förutom ovanstående har jag utfört såväl utbildnings som bedömningsuppdrag till ett
  flertal kommuner i Skåne.`
}
