import styled from '@emotion/styled'

/**
 * Renders the Container component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @param {*} props.children - the children.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const Container = props => {
  const { className = '', children } = props
  return (
    <StyledContainer {...props} className={`${className}`}>
      {children}
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  min-height: inherit;
  max-width: ${({ theme }) => theme.sizes.container[2]};
  width: ${({ theme }) => theme.sizes.percent[2]};
  margin: ${({ theme }) => theme.space[1]};
  padding: 3rem 0;
  @media (min-width: 500px) {
    width: ${({ theme }) => theme.sizes.percent[2]};
  }
`

/**
 * Renders the ContainerSmall component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @param {*} props.children - the children.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const ContainerSmall = props => {
  const { className = '', children } = props
  return (
    <StyledContainerSmall {...props} className={`${className}`}>
      {children}
    </StyledContainerSmall>
  )
}

const StyledContainerSmall = styled.div`
  min-height: inherit;
  max-width: ${({ theme }) => theme.sizes.container[0]};
  margin: ${({ theme }) => theme.space[1]};
  @media (min-width: 500px) {
    width: ${({ theme }) => theme.sizes.percent[2]};
  }
`

/**
 * Renders the ContainerFlex component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @param {*} props.children - the children.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const ContainerFlex = props => {
  const { className = '', children } = props
  return (
    <StyledContainerFlex {...props} className={`${className}`}>
      {children}
    </StyledContainerFlex>
  )
}

const StyledContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  max-width: inherit;
  width: inherit;
  margin: auto;
`

/**
 * Renders the ContainerGrid component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @param {*} props.children - the children.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const ContainerGrid = props => {
  const { className = '', children } = props
  return (
    <StyledGridContainer {...props} className={`${className}`}>
      {children}
    </StyledGridContainer>
  )
}

const StyledGridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${({ theme }) => theme.spaceX[8]};
  padding: ${({ theme }) => theme.spaceX[4]};
`
