import { useRouter } from 'next/router'

export function ActiveLink(props) {
  const { children, href } = props
  const router = useRouter()
  const style = {
    textDecoration: router.pathname === href && 'underline',
    fontWeight: 'bold'
  }

  function handleClick(event) {
    event.preventDefault()
    router.push(href)
  }

  return (
    <a href={href} onClick={handleClick} style={router.pathname === href ? style : null}>
      {children}
    </a>
  )
}
