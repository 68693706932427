/**
 * Renders the DocumentTextIcon component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const DocumentTextIcon = props => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
  )
}

/**
 * Renders the CameraIcon component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const CameraIcon = props => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  )
}

/**
 * Renders the FileUploadIcon component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const FileUploadIcon = props => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
      />
    </svg>
  )
}

/**
 * Renders the EditIcon component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const EditIcon = props => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
      />
    </svg>
  )
}

/**
 * Renders the EnvelopeIcon component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const EnvelopeIcon = props => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
    </svg>
  )
}

/**
 * Renders the PhoneIcon component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const PhoneIcon = props => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

/**
 * Renders the ChevronDoubleRightIcon component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const ChevronDoubleRightIcon = props => {
  const { className = '' } = props
  return (
    <svg
      {...props}
      className={`${className}`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
        clipRule="evenodd"
      />
    </svg>
  )
}
