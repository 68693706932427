export const bakgrundEnglishLocale = {
  listItemOne: 'Licensed psychologist',
  listItemTwo: 'Specialist in clinical psychology',
  listItemThree: 'Accredited healthcare provider for Region Skåne',
  backgroundTitle: 'Background',
  backgroundParagraphOne: `My name is Jonas Claesson. I have been a licensed psychologist since 1993 and a specialist in clinical psychology since 2004.`,
  backgroundParagraphTwo: `For a period I was involved in a research project on seasonal
  depression at Lund University Hospital. Furthermore, I work and have worked with
  consultation and training at Malmö University, debriefing training and
  crisis management to the police authority in Skåne and guidance to the Women's Emergency Service in
  Helsingborg.`,
  backgroundParagraphThree: `In addition to the above, I have performed both training and assessment assignments to a
  majority of municipalities in the region of Skåne.`
}
