import styled from '@emotion/styled'

import Link from 'next/link'

/**
 * Renders the PrimaryButtonLink component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @param {*} props.children - the children.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const PrimaryButtonLink = props => {
  const { className = '', children } = props
  return (
    <Link {...props} className={`${className}`}>
      <StyledButtonLink>{children}</StyledButtonLink>
    </Link>
  )
}

const StyledButtonLink = styled.a`
  display: block;
  width: ${props => (props.full ? '100%' : 'fit-content')};
  margin: ${props => (props.marginauto ? 'auto' : 'unset')};
  font-size: inherit;
  line-height: 3rem;
  border: 3px solid transparent;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: 0 20px;
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  -webkit-appearance: none;
  transition: background-color 0.2s ease, color 0.2s ease;
  outline: none;
  &:active {
    transform: translateY(3px);
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.background};
  }
  &:focus-visible {
    outline: 3px solid #6c60a6;
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.background};
  }
`
