import styled from '@emotion/styled'

/**
 * Renders the Article component.
 *
 * @param {*} props - the props.
 * @param {string} props.className - the className.
 * @param {*} props.children - the children.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const Article = props => {
  const { className = '', children } = props
  return (
    <StyledArticle {...props} className={`${className}`}>
      {children}
    </StyledArticle>
  )
}

const StyledArticle = styled.article`
  box-shadow: ${({ theme }) => theme.shadows[3]};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  h2 {
    margin-bottom: 0.5rem;
  }
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`
