import styled from '@emotion/styled'

/**
 * Renders the hamburger.
 *
 * @param {*} props - The props.
 * @returns {HTMLElement} - The HTML Markup.
 */
const Hamburger = props => {
  const { toggleNav } = props
  return (
    <HamburgerStyles {...props} onClick={toggleNav}>
      <span id="start-span"></span>
      <span id="middle-span-one" className="middle-span"></span>
      <span id="middle-span-two" className="middle-span"></span>
      <span id="end-span"></span>
    </HamburgerStyles>
  )
}

const HamburgerStyles = styled.div`
  width: var(--navbar-height);
  height: var(--navbar-height);
  position: relative;
  background-color: ${props =>
    props.navbarOpen ? 'rgb(244 244 244)' : props.theme.colors.background};
  cursor: pointer;
  transition: background-color ease 0.25s;
  :hover {
    background-color: rgb(244 244 244);

    span#start-span {
      transform: ${props =>
        props.navbarOpen
          ? 'translate(-50%, -50%) rotate(135deg)'
          : 'translate(-50%, -50%) rotate(0)'};
    }

    span#end-span {
      transform: ${props =>
        props.navbarOpen
          ? 'translate(-50%, -50%) rotate(45deg)'
          : 'translate(-50%, -50%) rotate(0)'};
    }
  }

  span {
    position: absolute;
    height: 4.5px;
    width: 56%;
    left: 50%;
    background-color: #4f5256;
    border-radius: 3px;
    transition: all 0.25s ease-in-out;
  }

  span#start-span {
    top: ${props => (props.navbarOpen ? '50%' : '30%')};
    left: ${props => (props.navbarOpen ? '50%' : '50%')};
    transform: ${props =>
      props.navbarOpen ? 'translate(-50%, -50%) rotate(45deg)' : 'translate(-50%, -50%) rotate(0)'};
  }

  span.middle-span {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  span#middle-span-one {
    left: ${props => (props.navbarOpen ? '-50%' : '50%')};
    opacity: ${props => (props.navbarOpen ? '0' : '1')};
  }

  span#middle-span-two {
    left: ${props => (props.navbarOpen ? '100%' : '50%')};
    opacity: ${props => (props.navbarOpen ? '0' : '1')};
  }

  span#end-span {
    top: ${props => (props.navbarOpen ? '50%' : '70%')};
    left: ${props => (props.navbarOpen ? '50%' : '50%')};
    transform: ${props =>
      props.navbarOpen
        ? 'translate(-50%, -50%) rotate(-45deg)'
        : 'translate(-50%, -50%) rotate(0)'};
  }
`

export { Hamburger }
