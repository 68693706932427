import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React from 'react';
import { useVacationAlert } from '../../../hooks/useVacation';
import { Select } from '../../UIComponents';

import { Alert } from "./Alert";

/**
 * The language component.
 *
 * @returns {HTMLElement} - The HTML Markup.
 *
 */
const Language = () => {
  const router = useRouter()
  const { setIsShowAlert, shouldShowAlert } = useVacationAlert('2024-08-10');

  /**
   * Function to change language.
   *
   * @param {*} value - The value.
   */
  function changeLanguage(value) {
    router.push(router.pathname, router.asPath, { locale: value })
  }

  return (
    <LanguageStyles shouldShowAlert={shouldShowAlert}>
      <Alert setIsShowAlert={setIsShowAlert} shouldShowAlert={shouldShowAlert} />
      <Select changeLanguage={changeLanguage} />
    </LanguageStyles>
  )
}

const LanguageStyles = styled.div`
  position: sticky;
  display: grid;
  grid-template-columns: 1fr;
  top: 5rem;
  z-index: 1;

  @media (min-width: 649px) {
    grid-template-columns: auto;
    display: flex;
    justify-content: space-between;
  }

  .menu-flags {
    position: ${({ shouldShowAlert }) => (shouldShowAlert ? 'relative' : 'absolute')};
    top: ${({ shouldShowAlert }) => (shouldShowAlert ? '0' : '1rem')};
    right: ${({ shouldShowAlert }) => (shouldShowAlert ? '0' : '1rem')};
    z-index: 1;
    border-radius: ${({ shouldShowAlert }) => (shouldShowAlert ? '0' : '20px')};
    background-color: #f9f9f9;
    color: ${({ theme }) => theme.colors.text};
    padding-bottom: 0;
    box-shadow: ${({ shouldShowAlert }) =>
      shouldShowAlert ? 'none' : '0px 2px 6px rgba(0, 0, 0, 0.1)'};
        border: ${({ shouldShowAlert }) =>
      shouldShowAlert ? 'none' : '1px solid #ccc'};
  }
  .menu-flags ul {
    padding: 0;
  }

  .menu-flags ul li {
    padding: 0.4rem 1rem;
    margin: 0;
  }

  .menu-flags button {
    border: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-family: inherit;
    color: #4d4d4d;
    background: transparent;
  }

  .menu-flags button span {
    cursor: pointer;
    padding: 0 8px;
    margin: 4px 0;
    white-space: nowrap;
  }

  .menu-flags button#rfs-btn {
    height: 100%;
  }

  .menu-flags button span:after {
    content: ' ';
    width: 0;
    height: 0;
    display: inline-block;
    margin-left: 5px;
  }
`;

export { Language };
