import { tailwind, baseColors } from '@theme-ui/preset-tailwind'

// console.log("Basecolors", baseColors)

export const paletteLight = {
  ...tailwind.colors,
  textColor: baseColors.gray[9],
  backgroundColor: 'hsl(0, 0%, 100%)',
  primaryColor: baseColors.gray[9],
  secondaryColor: 'hsl(220, 23%, 95%)',
  thirdColor: '#f7c1bd',
  accentColor: '#EB683E',
  highlightColor: baseColors.orange[7],
  mutedColor: baseColors.orange[7],
  activeLinkColor: 'slateblue',
  placeholderColor: 'rgb(106, 106, 106)',
  navbarColor: '#24292E',
  navbarMenuColor: 'hsla(210, 20%, 20%, 0.93)'
}

const theme = {
  initialColorModeName: 'light',
  useLocalStorage: true,
  useColorSchemeMediaQuery: false,
  ...tailwind,
  colors: {
    ...tailwind.colors,
    text: paletteLight.textColor,
    background: paletteLight.backgroundColor,
    primary: paletteLight.primaryColor,
    secondary: paletteLight.secondaryColor,
    accent: paletteLight.accentColor,
    highlight: paletteLight.highlightColor,
    muted: paletteLight.mutedColor,
    // Custom
    third: paletteLight.thirdColor,
    placeholder: paletteLight.placeholderColor,
    activeLink: paletteLight.activeLinkColor,
    boxShadow: paletteLight.placeholderColor,
    navbar: paletteLight.navbarColor,
    navbarMenu: paletteLight.navbarMenuColor
  },
  space: [0, 'auto', '0.5rem', '0.75rem', '1rem', '1.25rem', '1.5rem', '1.75rem', '2rem'],
  spaceX: [
    0,
    'auto',
    '0.5rem 0',
    '0.75rem 0',
    '1rem 0',
    '1.25rem 0',
    '1.5rem 0',
    '1.75rem 0',
    '2rem 0'
  ],
  spaceY: [
    0,
    'auto',
    '0 0.5rem',
    '0 0.75rem',
    '0 1rem',
    '0 1.25rem',
    '0 1.5rem',
    '0 1.75rem',
    '0 2rem'
  ],
  radii: [0, '3px', '5px', '10px', '20px', '50px'],
  fonts: {
    body: 'Open Sans, Montserrat, Segoe, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace'
  },
  fontSizes: {
    mobile: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
    tablet: [14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38],
    desktop: [16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40]
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.45,
    heading: 1.2
  },
  shadows: [
    '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    '0 0 8px 0 rgba(0, 0, 0, 0.2)',
    '0 0 2px 0 rgba(0, 0, 0, 0.4)',
    '0 0 6px 0 rgba(0, 0, 0, 0.1)',
    '0 0 4px 2px rgba(0, 0, 0, 0.12)'
  ],
  sizes: {
    ...tailwind.sizes,
    percent: ['70%', '80%', '90%', '100%'],
    container: ['450px', '768px', '960px', '1280px']
  },
  styles: {
    ...tailwind.styles,
    root: {
      fontFamily: 'body',
      fontWeight: 'body'
    },
    div: {
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[8]}`
    },
    h1: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: ['mobile.4', 'tablet.4', 'desktop.4'],
      /**
       * @param theme
       */
      color: theme => `${theme.colors.text}`,
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[4]}`
    },
    h2: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: ['mobile.4', 'tablet.4', 'desktop.4'],
      lineHeight: 'heading',
      /**
       * @param theme
       */
      color: theme => `${theme.colors.primary}`,
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[4]}`
    },
    h3: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: ['mobile.4', 'tablet.4', 'desktop.4'],
      lineHeight: 'heading',
      /**
       * @param theme
       */
      color: theme => `${theme.colors.primary}`,
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[4]}`
    },
    h4: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: ['mobile.4', 'tablet.4', 'desktop.4'],
      lineHeight: 'heading',
      /**
       * @param theme
       */
      color: theme => `${theme.colors.primary}`,
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[4]}`
    },
    h5: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: ['mobile.3', 'tablet.3', 'desktop.3'],
      lineHeight: 'heading',
      /**
       * @param theme
       */
      color: theme => `${theme.colors.primary}`,
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[4]}`
    },
    h6: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      fontSize: ['mobile.2', 'tablet.2', 'desktop.2'],
      lineHeight: 'heading',
      /**
       * @param theme
       */
      color: theme => `${theme.colors.primary}`,
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[4]}`
    },
    p: {
      fontSize: ['mobile.1', 'tablet.1', 'desktop.1'],
      /**
       * @param theme
       */
      padding: theme => `${theme.spaceX[2]}`
    },
    ul: {
      fontSize: ['mobile.1', 'tablet.1', 'desktop.1']
    },
    ol: {
      fontSize: ['mobile.1', 'tablet.1', 'desktop.1']
    },
    hr: {
      border: 0
    }
  },
  breakpoints: ['40em', '56em', '64em']
}

export default theme
