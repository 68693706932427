/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import { alertEnglishLocale, alertSwedishLocale } from '../../../locales';

export const Alert = ({ setIsShowAlert, shouldShowAlert }) => {
  const router = useRouter();
  const { locale } = router;
  const t = locale === 'en' ? alertEnglishLocale : alertSwedishLocale;

  if (!shouldShowAlert) {
    return null;
  }

  const alertStyle = css`
    width: 100%;
    padding: 20px 4px 20px 1.5rem;
    background-color: #f8ded2;
    color: #5d4539;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    opacity: ${shouldShowAlert ? 1 : 0};

    @media (min-width: 649px) {
      padding: 20px 1rem 20px 3rem;
    }
  `;

  const infoIconStyle = css`
    margin-right: 10px;
    font-size: 20px;
    color: #5d4539;
  `;

  const closeIconStyle = css`
    font-size: 20px;
  `;

  const closeButtonStyle = css`
    background: none;
    border: none;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    color: #5d4539;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #d9534f;
    }
  `;

  const contentStyle = css`
    flex: 1;
    margin-right: 15px;
    display: flex;
    align-items: center;
  `;

  return (
    <div css={alertStyle}>
      <div css={contentStyle}>
        <FaInfoCircle css={infoIconStyle} />
        <p>{t.content}</p>
      </div>
      <button
        css={closeButtonStyle}
        onClick={() => setIsShowAlert(false)}
        aria-label="Close alert"
      >
        <FaTimes css={closeIconStyle} />
      </button>
    </div>
  );
};
