import { useEffect, useState } from 'react';

export const useVacationAlert = (targetDate) => {
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [shouldShowAlert, setShouldShowAlert] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const vacationDate = new Date(targetDate);
    setShouldShowAlert(isShowAlert && currentDate < vacationDate);
  }, [isShowAlert, targetDate]);

  return { isShowAlert, setIsShowAlert, shouldShowAlert };
};
