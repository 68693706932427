import { useRef } from 'react'
import { Themed } from 'theme-ui'

import { GlobalStyle } from '../../styles'
import { DesktopLogo } from './DesktopLogo/DesktopLogo'
import { Navbar } from './Navbar/Navbar'
import { Language } from './Language/Language'
import { Main } from '../UIComponents'
import { Footer } from './Footer/Footer'

/**
 * Renders the layout component.
 *
 * @param {*} props - The props.
 * @returns {HTMLElement} - The HTML Markup.
 */
export const Layout = props => {
  const { children } = props

  const navbarMobileContainerRef = useRef(null)
  const navbarDesktopRef = useRef(null)
  const desktopLogoRef = useRef(null)

  return (
    <Themed.root>
      <GlobalStyle />
      <DesktopLogo desktopLogoRef={desktopLogoRef} />
      <Navbar
        navbarMobileContainerRef={navbarMobileContainerRef}
        navbarDesktopRef={navbarDesktopRef}
        desktopLogoRef={desktopLogoRef}
      />
      <Language />
      <Main>{children}</Main>
      <Footer />
    </Themed.root>
  )
}
