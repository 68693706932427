import { useState } from 'react'

import { useRouter } from 'next/router'
import ReactFlagsSelect from 'react-flags-select'

/**
 * Renders the language select component.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element} - The HTML Markup.
 */
export const Select = props => {
  const { changeLanguage } = props
  const router = useRouter()
  const { locale } = router

  const [selected, setSelected] = useState('')

  function handleCountryChange(countryCode) {
    let inputLowercase = countryCode.toLowerCase()

    setSelected(countryCode)

    if (inputLowercase === 'gb') {
      inputLowercase = 'en'
    } else if (inputLowercase === 'se') {
      inputLowercase = 'sv'
    }
    changeLanguage(inputLowercase)
  }

  const currentLanguage = locale === 'en' ? 'GB' : 'SE'

  return (
    <ReactFlagsSelect
      countries={['SE', 'GB']}
      selected={selected || currentLanguage}
      onSelect={handleCountryChange}
      customLabels={{ GB: 'English', SE: 'Swedish' }}
      placeholder={'Select a language'}
      className="menu-flags"
      selectButtonClassName="menu-flags-button"
    />
  )
}
