import styled from '@emotion/styled'

export const NavbarStyles = styled.nav`
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #efefef;

  div#navbar-mobile-container {
    display: none;
  }

  @media only screen and (max-width: 648px) {
    div#navbar-mobile-container {
      display: flex;
    }
  }
`

export const NavbarDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  z-index: 1;
  height: var(--navbar-height);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.18);
  user-select: none;
  padding: 0 2rem;

  ul.desktop-nav-ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
  }

  li {
    padding: 0 1rem;
  }

  li a {
    font-size: inherit;
    text-decoration: none;
    color: inherit;
    display: inline-block;
    transition: color 0.25s ease;
  }

  li a:hover {
    color: #000;
    font-weight: bold;
  }

  @media only screen and (max-width: 648px) {
    display: none;
  }
`

export const NavbarMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  z-index: 1;
  height: var(--navbar-height);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.18);
  user-select: none;

  div#mobile-nav-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    /* Needs 1 pixel because of outline, otherwise it looks off */
    padding-left: 1px;
  }

  ul#logo-section-mobile {
    list-style: none;
    padding: 0;
  }

  .logo-mobile-img {
    max-height: 44px;
  }

  /* TODO: #menu-ul not in use? */

  ul#menu-ul {
    list-style: none;
    padding: 0;

    li {
      color: #fff;
      font-size: 1.2rem;
      padding: 1rem;
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: color 0.2s ease;
    }

    a:hover {
      font-weight: bold;
    }
  }

  .active {
    color: ${({ theme }) => theme.colors.activeLink};
  }
`
